import React from 'react';
import './endPage.css';
import Result from './Result';
import {useNavigate} from "react-router-dom";
import answerResult from '../answerResult';


// 최대값 계산
function findMaxElementList(arr) {

    let maxElement = arr[0];
    let maxElementIndex = 0;

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] > maxElement) {
            maxElement = arr[i];
            maxElementIndex = i;
        }
    }
    return maxElementIndex;
}

// 결과 초기화 
function initAnswerResult(){
    answerResult[0].a = 0;
    answerResult[0].b = 0;
    answerResult[0].c = 0;
    answerResult[0].d = 0;
}

export default function EndPage() {
    const navigate = useNavigate();


    // Result json 결과를 통해 EndPage 결과 값 변경
    // (수정필요) 들여쓰기 테스트 
    // (수정필요) 수호신 배경 텍스트

    //answerResult 값으로 사용자 최종 수호신 인덱스 설정
    const list = [answerResult[0].a, answerResult[0].b, answerResult[0].c, answerResult[0].d];
    const index = findMaxElementList(list);
    console.log(answerResult[0].a, answerResult[0].b, answerResult[0].c, answerResult[0].d);

    // 수호신 기본 정보
    const anisrc = ["./res/endPageRes/dragon.png", "./res/endPageRes/bird.png", "./res/endPageRes/tiger.png", "./res/endPageRes/turtle.png"];
    const antiIntro = ["주체할 수 없이 뿜어져 나오는 생명력", "세상의 빛과 소금과 같은 존재","귀신 잡는 튼튼한 체력", "바라보는 대로 다 이루어내는"];
    const aniName = ["셋미르", "마아시", "하늬범", "높거북"];
    const aniExp = [
        "추위를 이겨내고 막 찾아온 봄에\n꽁꽁 얼어붙은 땅을 밟고 움트는 새싹과 같은 당신.\n동쪽의 사방신 청룡이 당신의 수호신이 되어줄 것이다.",
        "냉철한 판단력과 뜨거운 열정이 공존하여\n주변 사람들을 행복하게 만들어주는 당신.\n남쪽의 사방신 주작이 당신의 수호신이 되어줄 것이다.",
        "귀신마저 겁에 질려 도망갈 정도로 강인한 체력은\n당신이 해온 수련의 결과물이자 성공의 토대가 된다.\n서쪽의 사방신 백호가 당신의 수호신이 되어줄 것이다.",
        "가까운 사람들과 함께하는 것을 원동력으로 삼는\n당신의 꾸준한 노력은 역시, 진인사대천명.\n북쪽의 사방신 현무가 당신의 수호신이 되어줄 것이다."
    ];
    
    // 수호신 세부 정보
    const title = [
        ["계절: 봄", "적성: 명리학", "방위: 동" ], ["계절: 여름", "적성: 의술", "방위: 남" ], ["계절: 가을", "적성: 도술", "방위: 서"], ["계절: 겨울", "적성: 무속학", "방위: 북"],];
    const image = [
        ["./res/endPageRes/season1.png", "./res/endPageRes/subject1.png", "./res/endPageRes/east.svg","./res/endPageRes/final_bg_1.png"],
        ["./res/endPageRes/season2.png", "./res/endPageRes/subject2.png", "./res/endPageRes/south.svg","./res/endPageRes/final_bg_2.png" ],
        ["./res/endPageRes/season3.png", "./res/endPageRes/subject3.png", "./res/endPageRes/west.svg","./res/endPageRes/final_bg_3.png" ],
        ["./res/endPageRes/season4.png", "./res/endPageRes/subject4.png", "./res/endPageRes/north.svg","./res/endPageRes/final_bg_4.png" ]
    ];

    const explain = [
        [ 
            "겨울이 지나고 봄이 오듯\n 고생 끝에 낙이 온다는\n 희망을 잃지 말기를.",
            "문명의 전반적인 흐름을\n 읽어내는 학문이다.\n 누구나 공부해서\n 적용할 수 있다!",
            "동쪽에 떠오르는 햇살을 맞이하며, \n올해는 다 잘 풀리기를 기원하자.",
            "어둠이 있기 때문에\n 빛은 더욱 돋보이는 법이다.<br /> 가깝고도 먼 미래를 알아내보자.",
            "용호상박이라고 들어 보았는가?\n서로 마주보며 매일 으르렁대는\n사이. 도무지 좋아질 기미가 안 보여!"
        ],
        [
            "활기찬 여름날의 에너지는\n 괜시리 기분을 들뜨게 만드는\n 뭔가가 있다.",
            "옆에만 있어도 기분 좋게,\n 편안하게 만들어주는\n 좋은 기운을 타고났다.",
            "따뜻한 남쪽으로 가보자.\n 새로운 날씨와\n 새로운 사람들을 경험하며.",
            "공부도 체력이 있어야 잘하고,\n 체력에는 정신력이 포함된다.\n 무엇 하나 놓칠 수 없지!",
            "둘 다 에너지가 너무 높아서\n 함께 있으면 어디로 튈 지를\n 몰라서 곤란하다."
        ],
        [
            "야외 활동 하기에 딱 좋아서\n행복한 나날들,\n짧은 만큼 소중하다.",
            "마음을 비우고 득도하면\n삿된 것들을 물리치고\n자신의 중심을 다잡게 된다.",
            "태양은 서쪽에서 뜨지 않는다.\n그곳엔 백호가 자리하기 때문.",
            "공부도 체력이 있어야 잘하고,\n체력에는 정신력이 포함된다.\n무엇 하나 놓칠 수 없지!",
            "용호상박이라고 들어 보았는가?\n서로 마주보며 매일 으르렁대는\n사이. 도무지 좋아질 기미가 안 보여!"
        ],
        [
            "차가운 겨울날 소중한 이들과\n한 데 모여 온기를 나누고\n이야기꽃을 피우는 것이\n 행복이니까",
            "엄청난 행운!\n간절하게 바라면 이루어진다.\n그에 걸맞는 노력은 필수!",
            "찬바람 쌩쌩 불 것 같지만\n알고보면 포근하다고 한다.",
            "어둠이 있기 때문에\n빛은 더욱 돋보이는 법이다.\n가깝고도 먼 미래를 알아내보자.",
            "한 쪽은 신을 모시고,\n한 쪽은 귀신을 잡는다니!\n함께 가기는 힘들겠다."
        ]
    ];

    //궁합 정보
    const relations =[[anisrc[3], anisrc[2]], [anisrc[2], anisrc[0]], [anisrc[1], anisrc[0]], [anisrc[0], anisrc[2]]];

    //배경 style 추가 선언
    const backgroundStyle = { 
        backgroundImage: 'url(' + image[index][3] +')'
    }

    return (
        <div>
            <div className="endPageBody">

                <div className="endPageHeader">
                    <img src='./res/endPageRes/final_header.png'/>
                </div>

                <div className="endPageMain" style = {backgroundStyle}>

                    <div className="animalLine">
                        <img src= {anisrc[index]} width="40%" className="animal"/>
                        <div className="animalDescription">
                            <div className="animalP">
                                <p className="aniIntro">{antiIntro[index]}</p>
                                <p className="aniName">{aniName[index]}</p>
                                <p className="aniExp" style={{whiteSpace: "pre-wrap"}}>{aniExp[index]}</p>
                            </div>
                            <div className="moreInfo">
                                <svg className="endButton" width="258" height="88" viewBox="0 0 258 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_394_404)">
                                    <path d="M255.83 1.17969H1.17969V86.3897H255.83V1.17969Z" fill="#4AE6CA"/>
                                    <path d="M254.8 1.18C255.37 1.18 255.84 1.64 255.84 2.22V85.35C255.84 85.92 255.38 86.39 254.8 86.39H2.22C1.65 86.39 1.18 85.93 1.18 85.35V2.22C1.18 1.65 1.64 1.18 2.22 1.18H254.8ZM254.8 0H2.22C0.99 0 0 0.99 0 2.22V85.35C0 86.57 0.99 87.57 2.22 87.57H254.8C256.02 87.57 257.02 86.58 257.02 85.35V2.22C257.02 1 256.03 0 254.8 0Z" fill="#0F594B"/>
                                    <path d="M246.549 10.4697H10.4688V77.0997H246.549V10.4697Z" fill="#0F594B"/>
                                    <mask id="mask0_394_404" maskUnits="userSpaceOnUse" x="10" y="10" width="237" height="68">
                                    <path d="M246.549 10.4697H10.4688V77.0997H246.549V10.4697Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_394_404)">
                                    <path d="M180.778 110.42C189.978 110.42 197.438 102.96 197.438 93.7597C206.638 93.7597 214.098 86.2996 214.098 77.0996C223.298 77.0996 230.758 69.6396 230.758 60.4397C239.958 60.4397 247.418 52.9796 247.418 43.7796C247.418 34.5796 239.958 27.1197 230.758 27.1197C230.758 17.9197 223.298 10.4596 214.098 10.4596C214.098 1.25965 206.638 -6.20035 197.438 -6.20035C197.438 -15.4004 189.978 -22.8604 180.778 -22.8604H76.2377C67.0377 -22.8604 59.5777 -15.4004 59.5777 -6.20035C50.3777 -6.20035 42.9177 1.25965 42.9177 10.4596C33.7177 10.4596 26.2577 17.9197 26.2577 27.1197C17.0577 27.1197 9.59766 34.5796 9.59766 43.7796C9.59766 52.9796 17.0577 60.4397 26.2577 60.4397C26.2577 69.6396 33.7177 77.0996 42.9177 77.0996C42.9177 86.2996 50.3777 93.7597 59.5777 93.7597C59.5777 102.96 67.0377 110.42 76.2377 110.42H180.778Z" fill="#E06161" stroke="#FF9C9C" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M168.249 -22.8506C177.449 -22.8506 184.909 -15.3906 184.909 -6.19059C194.109 -6.19059 201.569 1.26941 201.569 10.4694C210.769 10.4694 218.229 17.9294 218.229 27.1294C227.429 27.1294 234.889 34.5894 234.889 43.7894C234.889 52.9894 227.429 60.4494 218.229 60.4494C218.229 69.6494 210.769 77.1094 201.569 77.1094C201.569 86.3094 194.109 93.7694 184.909 93.7694C184.909 102.969 177.449 110.429 168.249 110.429H88.7689C79.5689 110.429 72.1089 102.969 72.1089 93.7694C62.9089 93.7694 55.4489 86.3094 55.4489 77.1094C46.2489 77.1094 38.7889 69.6494 38.7889 60.4494C29.5889 60.4494 22.1289 52.9894 22.1289 43.7894C22.1289 34.5894 29.5889 27.1294 38.7889 27.1294C38.7889 17.9294 46.2489 10.4694 55.4489 10.4694C55.4489 1.26941 62.9089 -6.19059 72.1089 -6.19059C72.1089 -15.3906 79.5689 -22.8506 88.7689 -22.8506H168.249Z" fill="#2E6C9A" stroke="#4E8DC3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M155.489 110.42C164.689 110.42 172.149 102.96 172.149 93.7597C181.349 93.7597 188.809 86.2996 188.809 77.0996C198.009 77.0996 205.469 69.6396 205.469 60.4397C214.669 60.4397 222.129 52.9796 222.129 43.7796C222.129 34.5796 214.669 27.1197 205.469 27.1197C205.469 17.9197 198.009 10.4596 188.809 10.4596C188.809 1.25965 181.349 -6.20035 172.149 -6.20035C172.149 -15.4004 164.689 -22.8604 155.489 -22.8604H101.519C92.3189 -22.8604 84.8589 -15.4004 84.8589 -6.20035C75.6589 -6.20035 68.1989 1.25965 68.1989 10.4596C58.9989 10.4596 51.5389 17.9197 51.5389 27.1197C42.3389 27.1197 34.8789 34.5796 34.8789 43.7796C34.8789 52.9796 42.3389 60.4397 51.5389 60.4397C51.5389 69.6396 58.9989 77.0996 68.1989 77.0996C68.1989 86.2996 75.6589 93.7597 84.8589 93.7597C84.8589 102.96 92.3189 110.42 101.519 110.42H155.489Z" fill="#0F594B" stroke="#4AE6CA" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <path d="M246.549 10.47V77.1H10.4691V10.47H246.549ZM246.549 9.29004H10.4691C9.81906 9.29004 9.28906 9.82004 9.28906 10.47V77.1C9.28906 77.75 9.81906 78.28 10.4691 78.28H246.549C247.199 78.28 247.729 77.75 247.729 77.1V10.47C247.729 9.82004 247.199 9.29004 246.549 9.29004Z" fill="#0F594B"/>
                                    <path className="whiteLine" d="M89.8291 32.8499C89.7691 33.2799 89.7191 33.8199 89.7191 34.4199V46.6699C89.7191 51.3799 88.9491 53.8899 88.5491 54.9199C88.2891 55.2099 87.7791 55.3499 87.4391 55.2599C87.1791 54.4599 86.7291 52.7999 86.7291 48.5799V41.6399C85.9591 41.7199 85.4191 41.9299 85.1291 42.1799C84.6391 42.0399 84.0991 41.6099 83.6991 41.1799L83.2691 44.6099C83.7591 45.0099 84.4391 45.8899 84.4391 46.3499C84.4391 46.4899 84.2991 46.5799 84.0691 46.5799C83.7591 46.5799 82.5291 46.5499 80.6991 46.5499H76.0491C75.8791 47.0099 75.6191 47.4299 75.2791 47.7799C75.0191 48.0699 74.4491 48.2099 74.0791 48.1199C73.7691 47.3499 73.5691 45.2599 73.3891 42.5499L73.0491 37.5299C73.0191 37.1899 72.0791 36.4699 71.5091 36.2699C71.4791 36.0999 71.9691 35.5599 72.1991 35.5599C72.7691 35.5599 74.6791 35.6399 75.9391 35.8699H79.7691C80.7091 35.8699 81.1991 35.7299 81.5691 35.4099C81.9391 35.2399 84.3991 35.6699 84.3091 36.0699L83.8491 39.9199C84.5391 39.9999 85.5891 40.0299 86.7291 40.0599V34.1799C86.7291 33.6399 85.1591 32.5799 84.4691 32.2099C84.2691 32.1299 84.8991 31.5799 85.0691 31.5799C86.5791 31.5799 89.8991 32.4399 89.8391 32.8399L89.8291 32.8499ZM80.8391 37.4999H76.3291L76.2691 44.9199H80.6391L80.8391 37.4999ZM95.0291 31.2499C94.9691 31.6799 94.9191 32.2199 94.9191 32.8199V47.4899C94.9191 52.1699 94.1791 55.5699 93.7491 56.5999C93.4891 56.8899 92.8891 57.0299 92.5491 56.9399C92.3191 56.1399 91.8591 53.5999 91.8591 49.3699V32.7499C91.8591 32.0599 89.5791 30.7799 88.8591 30.4399C88.6891 30.3499 89.3191 29.6699 89.4891 29.6699C90.9691 29.6699 95.1391 30.5299 95.0291 31.2399V31.2499Z" fill="white"/>
                                    <path className="whiteLine" d="M107.38 43.3499C103.53 43.3499 100.98 41.3799 100.98 38.2699C100.98 35.3299 103.24 33.3899 106.72 33.1899C106.78 33.0799 106.81 32.9899 106.81 32.8999C106.81 32.6099 106.7 32.2699 106.3 31.9899C106.3 31.3299 108.5 31.9899 108.5 32.3299C108.5 32.4999 108.33 32.8699 108.21 33.1899C111.58 33.4799 113.78 35.3899 113.78 38.2699C113.78 41.3799 111.21 43.3499 107.39 43.3499H107.38ZM107.27 41.6099C109.27 41.6099 110.61 40.2999 110.61 38.1599C110.61 36.0199 109.3 34.7899 107.33 34.7899C105.36 34.7899 104.13 36.0999 104.13 38.1599C104.13 40.2199 105.41 41.6099 107.27 41.6099ZM122.83 55.7099C122.83 55.8499 122.72 55.9399 122.49 55.9399H109.96C107.76 55.9399 106.99 55.1999 106.93 53.5099L106.87 51.3399C106.87 50.9999 106.01 50.5699 105.44 50.3699C105.41 50.1999 105.9 49.6299 106.18 49.6299C106.81 49.6299 109.12 49.7399 110.01 50.0599H117.58L117.64 47.5499H110.9C109.73 47.5499 108.93 47.8599 108.56 48.1799C107.62 47.9199 105.88 46.4999 105.76 46.1499C105.73 46.0399 105.93 45.6899 106.05 45.7199C106.59 45.8599 108.59 45.9499 110.5 45.9499H116.38C117.32 45.9499 117.84 45.7499 118.18 45.3799C118.58 45.3499 121.21 45.7499 121.12 46.1499L120.29 49.9199C120.92 50.3499 121.29 51.1499 121.29 51.3499C121.29 51.5199 121.2 51.6399 121.03 51.6399H110.01V53.0099C110.01 54.0899 110.32 54.3499 111.81 54.3499H117.66C119.69 54.3499 120.34 53.2099 120.66 53.2099C121 53.2099 122.83 54.9199 122.83 55.7199V55.7099ZM116.01 29.6699C117.52 29.6699 121.72 30.5299 121.63 31.2399C121.57 31.6699 121.52 32.2099 121.52 32.8099V38.7799C121.52 41.6599 120.72 43.6299 120.29 44.5199C120.03 44.8099 119.43 44.9499 119.09 44.8599C118.86 44.0599 118.38 42.7999 118.38 38.7799V32.7599C118.38 32.0699 116.1 30.7899 115.38 30.4499C115.21 30.3599 115.84 29.6799 116.01 29.6799V29.6699Z" fill="white"/>
                                    <path className="whiteLine" d="M133.668 47.89C133.328 47.86 133.008 47.32 133.008 47C137.208 44.17 141.178 39.46 143.228 35.61L139.688 35.64C138.488 35.64 137.718 35.95 137.348 36.27C136.408 36.01 134.638 34.59 134.548 34.24C134.518 34.13 134.688 33.75 134.838 33.78C135.378 33.95 137.378 34.07 139.288 34.04L143.028 33.98C143.968 33.95 144.458 33.81 144.828 33.49C145.198 33.35 147.878 34.12 147.708 34.46C146.628 36.6 145.138 38.66 143.398 40.54C145.658 41.91 147.628 43.19 148.198 43.77C148.878 44.46 147.398 48.22 146.908 47.54C145.648 45.63 144.028 43.51 142.368 41.63C139.598 44.4 136.428 46.65 133.658 47.88L133.668 47.89ZM157.108 55.43C157.108 55.57 156.998 55.66 156.768 55.66L144.068 55.72C141.268 55.72 140.728 54.78 140.728 52.92V49.92C140.728 49.41 139.618 48.66 139.068 48.49C138.898 48.4 139.528 47.63 139.808 47.63C140.948 47.63 142.638 47.92 143.488 48.2C143.688 48.26 143.948 48.34 143.888 48.74C143.828 49.14 143.828 49.74 143.828 50.28V52.94C143.828 53.94 144.168 54.05 145.058 54.05L151.938 54.02C153.968 54.02 154.648 52.91 154.938 52.91C155.308 52.91 157.108 54.65 157.108 55.42V55.43ZM150.428 29.71C151.908 29.71 156.108 30.57 156.028 31.28C155.968 31.71 155.918 32.25 155.918 32.85V44.21C155.918 47.09 155.118 49.06 154.718 49.95C154.458 50.24 153.858 50.38 153.518 50.29C153.258 49.46 152.808 48.21 152.808 44.21V40.61H150.928C149.758 40.61 148.958 40.87 148.618 41.21C147.678 40.92 146.478 39.55 146.388 39.21C146.358 39.1 146.558 38.75 146.678 38.78C147.218 38.92 149.218 39.01 151.128 39.01H152.808V32.79C152.808 32.1 150.528 30.82 149.808 30.48C149.638 30.39 150.238 29.71 150.438 29.71H150.428Z" fill="white"/>
                                    <path className="whiteLine" d="M185.919 44.9198C185.919 45.0598 185.809 45.1498 185.579 45.1498L164.969 45.1798C163.799 45.1798 162.999 45.4898 162.659 45.8398C161.719 45.5798 159.949 44.1598 159.859 43.7798C159.829 43.6698 159.999 43.3198 160.149 43.3498C160.689 43.4598 162.689 43.5798 164.599 43.5798L171.569 43.5498L171.509 41.3198C171.509 40.8098 170.399 39.9798 169.679 39.6398C169.509 39.5498 170.139 39.0098 170.309 39.0098C171.789 39.0098 174.909 39.6698 174.819 40.0898C174.759 40.3798 174.559 42.0598 174.359 43.5398L180.759 43.5098C182.789 43.5098 183.439 42.3698 183.759 42.3698C184.099 42.3698 185.929 44.1398 185.929 44.9098L185.919 44.9198ZM161.629 40.2998C165.879 38.4698 169.939 35.4698 171.789 32.7098C172.019 32.3698 171.109 31.4798 170.559 31.1398C170.419 31.0598 171.159 30.5098 171.359 30.5098C172.359 30.5098 174.989 31.6798 175.639 32.1398C176.009 32.3998 176.149 32.6298 175.869 33.0198C175.329 33.7898 174.699 34.4998 174.009 35.1598C177.009 35.7598 180.179 36.4998 181.549 36.9598C182.489 37.2698 182.429 41.0998 181.749 40.6698C178.919 38.8998 175.699 37.5298 172.729 36.2998C169.499 38.8698 165.419 40.4698 162.279 41.1798C161.909 41.1498 161.599 40.6098 161.619 40.2998H161.629ZM179.329 52.2898C179.329 55.1398 176.849 57.0298 173.109 57.0298C169.369 57.0298 166.859 55.1498 166.859 52.2898C166.859 49.6098 169.059 47.7798 172.459 47.5798C172.489 47.4698 172.519 47.3798 172.519 47.2898C172.519 46.9998 172.439 46.6598 172.029 46.3798C172.029 45.7198 174.229 46.3798 174.229 46.7198C174.229 46.8898 174.059 47.2598 173.939 47.5798C177.219 47.8398 179.339 49.6598 179.339 52.2898H179.329ZM176.219 52.2298C176.219 50.3698 174.959 49.1498 173.049 49.1498C171.139 49.1498 169.969 50.3798 169.969 52.2298C169.969 54.0798 171.169 55.3098 172.999 55.3098C174.939 55.3098 176.229 54.0798 176.229 52.2298H176.219Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_394_404">
                                    <rect width="257.02" height="87.57" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>                                
                                    <svg onClick={() => { initAnswerResult(); navigate('/');} } className="endButton" width="258" height="88" viewBox="0 0 258 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_394_336)">
                                        <path d="M255.83 1.17969H1.17969V86.3897H255.83V1.17969Z" fill="#4AE6CA"/>
                                        <path d="M254.8 1.18C255.37 1.18 255.84 1.64 255.84 2.22V85.35C255.84 85.92 255.38 86.39 254.8 86.39H2.22C1.65 86.39 1.18 85.93 1.18 85.35V2.22C1.18 1.65 1.64 1.18 2.22 1.18H254.8ZM254.8 0H2.22C0.99 0 0 0.99 0 2.22V85.35C0 86.57 0.99 87.57 2.22 87.57H254.8C256.02 87.57 257.02 86.58 257.02 85.35V2.22C257.02 1 256.03 0 254.8 0Z" fill="#0F594B"/>
                                        <path d="M246.549 10.4697H10.4688V77.0997H246.549V10.4697Z" fill="#0F594B"/>
                                        <mask id="mask0_394_336"  maskUnits="userSpaceOnUse" x="10" y="10" width="237" height="68">
                                        <path d="M246.549 10.4697H10.4688V77.0997H246.549V10.4697Z" fill="white"/>
                                        </mask>
                                        <g mask="url(#mask0_394_336)">
                                        <path d="M180.778 110.42C189.978 110.42 197.438 102.96 197.438 93.7597C206.638 93.7597 214.098 86.2996 214.098 77.0996C223.298 77.0996 230.758 69.6396 230.758 60.4397C239.958 60.4397 247.418 52.9796 247.418 43.7796C247.418 34.5796 239.958 27.1197 230.758 27.1197C230.758 17.9197 223.298 10.4596 214.098 10.4596C214.098 1.25965 206.638 -6.20035 197.438 -6.20035C197.438 -15.4004 189.978 -22.8604 180.778 -22.8604H76.2377C67.0377 -22.8604 59.5777 -15.4004 59.5777 -6.20035C50.3777 -6.20035 42.9177 1.25965 42.9177 10.4596C33.7177 10.4596 26.2577 17.9197 26.2577 27.1197C17.0577 27.1197 9.59766 34.5796 9.59766 43.7796C9.59766 52.9796 17.0577 60.4397 26.2577 60.4397C26.2577 69.6396 33.7177 77.0996 42.9177 77.0996C42.9177 86.2996 50.3777 93.7597 59.5777 93.7597C59.5777 102.96 67.0377 110.42 76.2377 110.42H180.778Z" fill="#E06161" stroke="#FF9C9C" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M168.249 -22.8506C177.449 -22.8506 184.909 -15.3906 184.909 -6.19059C194.109 -6.19059 201.569 1.26941 201.569 10.4694C210.769 10.4694 218.229 17.9294 218.229 27.1294C227.429 27.1294 234.889 34.5894 234.889 43.7894C234.889 52.9894 227.429 60.4494 218.229 60.4494C218.229 69.6494 210.769 77.1094 201.569 77.1094C201.569 86.3094 194.109 93.7694 184.909 93.7694C184.909 102.969 177.449 110.429 168.249 110.429H88.7689C79.5689 110.429 72.1089 102.969 72.1089 93.7694C62.9089 93.7694 55.4489 86.3094 55.4489 77.1094C46.2489 77.1094 38.7889 69.6494 38.7889 60.4494C29.5889 60.4494 22.1289 52.9894 22.1289 43.7894C22.1289 34.5894 29.5889 27.1294 38.7889 27.1294C38.7889 17.9294 46.2489 10.4694 55.4489 10.4694C55.4489 1.26941 62.9089 -6.19059 72.1089 -6.19059C72.1089 -15.3906 79.5689 -22.8506 88.7689 -22.8506H168.249Z" fill="#2E6C9A" stroke="#4E8DC3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M155.489 110.42C164.689 110.42 172.149 102.96 172.149 93.7597C181.349 93.7597 188.809 86.2996 188.809 77.0996C198.009 77.0996 205.469 69.6396 205.469 60.4397C214.669 60.4397 222.129 52.9796 222.129 43.7796C222.129 34.5796 214.669 27.1197 205.469 27.1197C205.469 17.9197 198.009 10.4596 188.809 10.4596C188.809 1.25965 181.349 -6.20035 172.149 -6.20035C172.149 -15.4004 164.689 -22.8604 155.489 -22.8604H101.519C92.3189 -22.8604 84.8589 -15.4004 84.8589 -6.20035C75.6589 -6.20035 68.1989 1.25965 68.1989 10.4596C58.9989 10.4596 51.5389 17.9197 51.5389 27.1197C42.3389 27.1197 34.8789 34.5796 34.8789 43.7796C34.8789 52.9796 42.3389 60.4397 51.5389 60.4397C51.5389 69.6396 58.9989 77.0996 68.1989 77.0996C68.1989 86.2996 75.6589 93.7597 84.8589 93.7597C84.8589 102.96 92.3189 110.42 101.519 110.42H155.489Z" fill="#0F594B" stroke="#4AE6CA" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <path class="writing" d="M246.549 10.47V77.1H10.4691V10.47H246.549ZM246.549 9.29004H10.4691C9.81906 9.29004 9.28906 9.82004 9.28906 10.47V77.1C9.28906 77.75 9.81906 78.28 10.4691 78.28H246.549C247.199 78.28 247.729 77.75 247.729 77.1V10.47C247.729 9.82004 247.199 9.29004 246.549 9.29004Z" fill="#0F594B"/>
                                        <path class="whiteLine" d="M90.3097 46.3199C90.9697 47.0599 89.6497 50.7399 89.1097 49.9699C88.0497 48.4899 86.3997 46.0899 84.7997 44.1699C82.0297 46.7999 78.6897 49.1399 75.0897 50.6799C74.7497 50.6499 74.4297 50.1699 74.4297 49.8199C78.6797 47.0499 83.6197 41.7999 85.5397 38.5099L81.2897 38.5399C80.1197 38.5399 79.3197 38.8499 78.9497 39.1699C78.0097 38.9099 76.2697 37.4899 76.1497 37.1399C76.1197 37.0299 76.3197 36.6499 76.4397 36.6799C76.9797 36.8499 78.9797 36.9699 80.8897 36.9399L85.5997 36.8799C86.5397 36.8799 87.0597 36.7099 87.3997 36.3899C87.7697 36.2499 90.5097 36.9599 90.2797 37.3599C89.1697 39.2399 87.6497 41.2399 85.8597 43.0999C87.8297 44.3299 89.5697 45.4699 90.3097 46.2999V46.3199ZM81.9497 34.8099C81.0397 34.8099 79.0397 32.7299 78.9197 32.4699C78.8597 32.3299 79.0597 31.8399 79.2597 31.8699C80.3997 32.0699 81.5097 32.2999 82.9397 32.4699L86.3397 32.8099C86.6297 32.8399 87.9697 34.8099 87.0797 34.8099H81.9397H81.9497ZM97.8797 31.2399C97.8197 31.6699 97.7697 32.2099 97.7697 32.8099V47.4799C97.7697 52.1599 96.9697 55.5599 96.5397 56.5899C96.2797 56.8799 95.6797 57.0199 95.3397 56.9299C95.1097 56.1299 94.6297 53.5899 94.6297 49.3699V42.8299H92.9497C91.7497 42.8299 90.9797 43.0899 90.6097 43.3999C89.6697 43.1399 88.4997 41.7699 88.4097 41.4299C88.3797 41.3199 88.5497 40.9399 88.6697 40.9699C89.2397 41.1399 91.2397 41.2299 93.1497 41.2299H94.6297V32.7499C94.6297 32.0699 92.3497 30.7799 91.6297 30.4399C91.4597 30.3599 92.0897 29.6699 92.2597 29.6699C93.7497 29.6699 97.9697 30.5299 97.8797 31.2399Z" fill="white"/>
                                        <path class="whiteLine" d="M127.248 44.4898C127.248 44.6298 127.108 44.7198 126.878 44.7198L106.898 44.7498C105.728 44.7498 104.928 45.0598 104.558 45.3798C103.618 45.1198 101.878 43.6998 101.788 43.3498C101.728 43.2398 101.928 42.8898 102.048 42.9198C102.588 43.0598 104.588 43.1498 106.498 43.1498L122.058 43.0898C124.088 43.0898 124.768 41.9498 125.058 41.9498C125.428 41.9498 127.258 43.6898 127.258 44.4898H127.248ZM121.508 53.7398C121.968 54.1698 122.588 55.0298 122.588 55.4498C122.588 55.5898 122.478 55.7098 122.218 55.7098C121.908 55.7098 120.678 55.6798 118.848 55.6798H111.058C110.888 56.1398 110.598 56.5398 110.258 56.8798C109.998 57.1898 109.428 57.3398 109.058 57.2498C108.748 56.4498 108.568 54.3898 108.288 51.8498L107.998 49.3898C107.968 49.0498 107.028 48.3298 106.458 48.1298C106.428 47.9598 106.918 47.4198 107.148 47.4198C107.718 47.4198 109.658 47.5098 110.918 47.7298H117.908C118.848 47.7298 119.368 47.5898 119.708 47.2698C120.078 47.1298 122.508 47.5298 122.448 47.9298L121.508 53.7198V53.7398ZM108.258 36.0398C108.258 33.3298 110.598 31.4998 114.198 31.2998C114.228 31.1898 114.258 31.0998 114.258 31.0098C114.258 30.6998 114.168 30.3798 113.768 30.1498C113.768 29.4898 115.968 30.1498 115.968 30.4398C115.968 30.5798 115.798 30.9798 115.658 31.2998C119.138 31.5298 121.398 33.3598 121.398 36.0398C121.398 38.8898 118.798 40.7498 114.858 40.7498C110.918 40.7498 108.268 38.8898 108.268 36.0398H108.258ZM118.938 49.3698H111.318V54.0198H118.768L118.938 49.3698ZM111.398 35.9498C111.398 37.8598 112.768 39.1498 114.738 39.1498C116.818 39.1498 118.248 37.8598 118.248 35.9498C118.248 34.0398 116.848 32.7198 114.788 32.7198C112.728 32.7198 111.388 34.0298 111.388 35.9498H111.398Z" fill="white"/>
                                        <path class="whiteLine" d="M155.231 51.9403C155.231 52.0803 155.121 52.1703 154.891 52.1703L134.281 52.2003C133.111 52.2003 132.311 52.5103 131.971 52.8603C131.031 52.5703 129.261 51.1503 129.171 50.8103C129.141 50.6903 129.311 50.3503 129.461 50.3803C130.001 50.5203 132.001 50.6103 133.911 50.6103L150.071 50.5503C152.101 50.5503 152.751 49.4103 153.071 49.4103C153.411 49.4103 155.241 51.1503 155.241 51.9503L155.231 51.9403ZM134.651 39.3803C134.651 36.0403 137.331 33.7603 141.441 33.5603C141.471 33.4403 141.501 33.3603 141.501 33.2703C141.501 32.9803 141.411 32.6703 140.991 32.3603C140.991 31.7003 143.221 32.3603 143.221 32.7303C143.221 32.8703 143.051 33.2403 142.931 33.5603C146.901 33.8503 149.501 36.1003 149.501 39.3803C149.501 43.0303 146.531 45.4303 142.081 45.4303C137.631 45.4303 134.661 43.0303 134.661 39.3803H134.651ZM138.081 39.2903C138.081 41.9403 139.651 43.7103 141.961 43.7103C144.421 43.7103 146.071 41.9403 146.071 39.2903C146.071 36.8103 144.441 35.1203 142.021 35.1203C139.601 35.1203 138.081 36.8003 138.081 39.2903Z" fill="white"/>
                                        <path class="whiteLine" d="M182.63 52.03C182.63 52.17 182.52 52.26 182.29 52.26L161.68 52.29C160.51 52.29 159.71 52.6 159.37 52.95C158.43 52.66 156.66 51.24 156.57 50.89C156.54 50.78 156.71 50.43 156.86 50.46C157.4 50.6 159.4 50.69 161.31 50.69L168.22 50.66L168.16 46.12H164.36C162.16 46.12 161.36 45.35 161.33 43.66L161.27 40.29C161.27 39.98 160.41 39.52 159.87 39.32C159.84 39.15 160.3 38.61 160.58 38.61C161.21 38.61 163.52 38.72 164.41 39.01H174.03L174.09 35.13H165.15C163.98 35.13 163.18 35.47 162.84 35.79C161.9 35.53 160.13 34.11 160.04 33.73C160.01 33.62 160.18 33.27 160.3 33.3C160.87 33.44 162.87 33.53 164.78 33.53H172.86C173.8 33.53 174.29 33.33 174.66 32.99C175.03 32.96 177.66 33.33 177.6 33.73L176.77 38.87C177.4 39.3 177.77 40.13 177.77 40.3C177.77 40.47 177.68 40.59 177.51 40.59H164.41V43.16C164.41 44.27 164.75 44.5 166.21 44.5H173.69C175.72 44.5 176.4 43.36 176.69 43.36C177.03 43.36 178.86 45.1 178.86 45.9C178.86 46.04 178.75 46.13 178.52 46.13H171.47L171.27 50.67L177.47 50.64C179.5 50.64 180.15 49.5 180.47 49.5C180.81 49.5 182.64 51.24 182.64 52.04L182.63 52.03Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_394_336">
                                        <rect width="257.02" height="87.57" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                            </div>
                        </div>
                    </div>

                    
                    <div className="resultLine" style={{whiteSpace: "pre-wrap"}}>
                        <Result 
                            title={title[index][0]}
                            imgsrc={image[index][0]} 
                            width="150px" 
                            height="150px" 
                            explain={explain[index][0]}
                            cls="results"
                        />
                        <Result 
                            title={title[index][1]} 
                            imgsrc={image[index][1]} 
                            width="150px" 
                            height="150px" 
                            explain={explain[index][1]}
                            cls="results"
                        />
                        <Result 
                            title= {title[index][2]}
                            imgsrc= {image[index][2]} 
                            width="150px" 
                            height="150px" 
                            explain= {explain[index][2]}
                            cls="results"
                        />
                    </div>

                    <div className="compatibilityLine" style={{whiteSpace: "pre-wrap"}}>
                        <Result 
                                title="환상 궁합" 
                                imgsrc={relations[index][0]} 
                                width="200px" 
                                height="auto" 
                                explain={explain[index][3]} 
                                cls="compatibility"
                        />
                        <Result 
                                title="환장 궁합" 
                                imgsrc={relations[index][1]} 
                                width="200px" 
                                height="auto" 
                                explain={explain[index][4]}
                                cls="compatibility"
                        />
                    </div>
                    
                    <div className="logoLine">
                        <img src="./res/logo_w.svg" width="40%" className="logoImage"/>
                    </div>

                </div>

                <div className="endPageFooter">
                    <img src='./res/endPageRes/final_footer.png'/>
                </div>
            </div>
        </div>
    );
}

